<template>
  <div class="my-3">
    <v-row class="px-3">
      <v-btn
        color="primary"
        dark
        @click="showModal = true"
      >
        Add new
      </v-btn>
      <admin-dialog-form
        v-if="showModal"
        :show.sync="showModal"
        :role="role"
        title="Add administator"
      />
      <v-spacer></v-spacer>
      <div>
        <v-text-field
          v-model="search"
          :append-icon="icons.mdiMagnify"
          label="Search"
          single-line
          dense
          outlined
        ></v-text-field>
      </div>
    </v-row>
    <admins-table
      ref="adminsTable"
      :search="search"
      @change="refetchData"
    />
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref } from '@vue/composition-api'
import { mdiMagnify } from '@mdi/js'
import AdminsTable from '@/views/components/administrators/AdminsTable.vue'
import AdminDialogForm from '@/views/components/administrators/AdminDialogForm.vue'

export default {
  components: {
    AdminsTable,
    AdminDialogForm,
  },
  setup() {
    const role = localStorage.getItem('userAbility')
    const search = ref('')
    const showModal = ref(false)

    return {
      icons: {
        mdiMagnify,
      },
      role,
      search,
      showModal,
    }
  },

  methods: {
    refetchData() {
      this.$refs.adminsTable.getAdmins()
    },
  },

  watch: {
    showModal(newVal, oldVal) {
      if (newVal === false && oldVal === true) this.refetchData()
    },
  },
}
</script>
