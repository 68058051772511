<template>
  <div>
    <loading v-if="$apollo.loading" />
    <v-simple-table v-else>
      <template v-slot:default>
        <thead>
          <tr>
            <th
              @click="changeOrder('EMAIL')"
              class="text-uppercase"
            >
              Email
              <sorting-arrows />
            </th>
            <th
              v-if="role == 'superadmin'"
              class="text-center text-uppercase"
              @click="changeOrder('ROLE')"
            >
              Role
              <sorting-arrows />
            </th>
            <th
              v-if="role == 'superadmin'"
              class="text-center text-uppercase"
              @click="changeOrder('ORGANIZATION_NAME')"
            >
              Organization
              <sorting-arrows />
            </th>
            <th
              @click="changeOrder('LAST_SESSION')"
              class="text-center text-uppercase"
            >
              Last Session
              <sorting-arrows />
            </th>
            <th class="text-center text-uppercase">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="admins.length == 0">
            <div class="text-center my-5">
              <h3>
                No items
              </h3>
            </div>
          </tr>
          <tr
            v-else
            v-for="item in admins"
            :key="item.id"
          >
            <td>{{ item.email }}</td>
            <td v-if="role == 'superadmin'" class="text-center">
              {{ item.role[0].toUpperCase() + item.role.slice(1) }}
            </td>
            <td v-if="role == 'superadmin'" class="text-center">
              <v-chip
                :color="item.organization.color"
                small
                class="font-weight-semibold text-capitalize"
              >
                {{ item.organization.name }}
              </v-chip>
            </td>
            <td class="text-center">
              {{ item.last_session ? moment(item.last_session).format('YYYY-MM-DD') : 'Never' }}
            </td>
            <td class="text-center">
              <v-btn
                v-if="role == 'superadmin'"
                icon
                small
                @click="() => {
                  selectedAdmin = item
                  showModal = true
                }"
              >
                <v-icon size="20">
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </v-btn>
              <v-btn
                icon
                small
                @click="() => {
                  selectedAdmin = item
                  showDeleteModal = true
                }"
              >
                <v-icon size="20">
                  {{ icons.mdiTrashCanOutline  }}
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <pagination
      v-if="admins"
      :count="countPages"
      :page.sync="currentPage"
    />
    <admin-dialog-form
      v-if="showModal && role == 'superadmin'"
      @success="getAdmins()"
      :show.sync="showModal"
      :admin.sync="selectedAdmin"
      :role="role"
    />
    <delete-dialog
      v-if="showDeleteModal"
      :show.sync="showDeleteModal"
      title="Delete User"
      @success="getAdmins()"
      :admin.sync="selectedAdmin"
    />

  </div>
</template>

<script>
import { required, emailValidator, pinLength } from '@core/utils/validation'
import { ref } from '@vue/composition-api'
import { mdiPencilOutline, mdiTrashCanOutline } from '@mdi/js'
// import axios from '@axios'
import Pagination from '@/views/components/pagination/Pagination.vue'
import gql from 'graphql-tag'
import Loading from '../Loading.vue'
import AdminDialogForm from './AdminDialogForm.vue'
import DeleteDialog from '../DeleteDialog.vue'
import SortingArrows from '../SortingArrows.vue'

export default {
  name: 'AdminsTable',
  components: {
    Pagination,
    Loading,
    AdminDialogForm,
    DeleteDialog,
    SortingArrows,
  },
  props: {
    search: {
      required: false,
      default: null,
      type: String,
    },
  },
  apollo: {
    users: {
      query: gql`
        query ($page: Int!, $search: String, $orderBy: [QueryUsersOrderByOrderByClause!]) {
          users(page: $page, search: $search, orderBy: $orderBy) {
            data {
              id
              email
              last_session
              role
              organization {
                id
                name
                color
              }
            }
            paginatorInfo {
              currentPage
              lastPage
            }
          }
        }
      `,
      debounce: 350,
      variables() {
        return {
          search: this.search ?? undefined,
          orderBy: [
            {
              column: this.column ?? 'EMAIL',
              order: this.order ?? 'ASC',
            },
          ],
          page: this.currentPage,
        }
      },
      result(result) {
        this.admins = result.data.users.data
        this.countPages = result.data.users.paginatorInfo.lastPage
        this.currentPage = result.data.users.paginatorInfo.currentPage
      },
    },
  },
  setup(props) {
    const role = localStorage.getItem('userAbility')
    const admins = ref([])
    const currentPage = ref(1)
    const countPages = ref(1)
    const searchProp = ref(props.search)
    const showModal = ref(false)
    const showDeleteModal = ref(false)
    const selectedAdmin = ref(null)
    const column = ref('EMAIL')
    const order = ref('ASC')

    // const getAdmins = async () => {
    //   await axios
    //     .get('/admins', {
    //       params: {
    //         page: currentPage.value,
    //       },
    //     })
    //     .then(response => {
    //       admins.value = response.data.administrators.data
    //       currentPage.value = response.data.administrators.currentPage
    //       countPages.value = response.data.administrators.last_page
    //     })
    //     .catch(error => {
    //       console.log(error)
    //     })
    // }

    return {
      validators: {
        required,
        emailValidator,
        pinLength,
      },

      icons: {
        mdiPencilOutline,
        mdiTrashCanOutline,
      },
      admins,
      role,
      currentPage,
      countPages,
      searchProp,
      showModal,
      selectedAdmin,
      showDeleteModal,
      // getAdmins,
      column,
      order,
    }
  },

  methods: {
    getAdmins() {
      this.$apollo.queries.users.refetch()
    },
    changeOrder(column) {
      this.column = column
      this.order = this.order === 'ASC' ? 'DESC' : 'ASC'
    },
  },
}
</script>
