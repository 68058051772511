<template>
  <v-dialog
    v-model="isDialogVisible"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ admin ? 'Edit administrator' : 'Add new administrator' }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                dense
                v-model="adminsEmail"
                label="Email"
                :disabled="admin != null"
                type="email"
                :error-messages="errorMessages.email"
                :rules="[validators.required, validators.emailValidator]"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" v-if="usersRole === 'superadmin'">
              <select-organization :organization.sync="adminsOrganizationName"></select-organization>
            </v-col>
            <v-col cols="12" v-if="usersRole === 'superadmin'">
              <v-autocomplete
                :items="['Admin', 'Superadmin']"
                label="Role"
                dense
                v-model="adminsRole"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          @click="closeModal"
        >
          Close
        </v-btn>
        <v-btn
          color="success"
          :disabled="!isValid"
          @click="save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed, onMounted, ref, getCurrentInstance } from '@vue/composition-api'
import SelectOrganization from '@/views/components/SelectOrganization.vue'
import { required, emailValidator } from '@core/utils/validation'
// import axios from '@axios'
import gql from 'graphql-tag'

export default {
  name: 'AdminDialogForm',
  components: { SelectOrganization },
  props: {
    admin: {
      type: Object,
      required: false,
      default: null,
    },
    role: {
      type: String,
      required: true,
      default: 'admin',
    },
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup(props, { emit, root }) {
    const isDialogVisible = ref(props.show)
    const adminsEmail = ref(undefined)
    const adminsRole = ref(undefined)
    const adminsOrganizationName = ref([])
    const errorMessages = ref([])
    const usersRole = ref(props.role)
    const organizationName = JSON.parse(localStorage.getItem('userData')).organization_name
    const vm = getCurrentInstance().proxy

    // const isEmailValid = ref(false)
    // const role = localStorage.getItem('userAbility')

    const closeModal = () => {
      isDialogVisible.value = false
      emit('update:show', false)
      emit('update:admin', null)
    }

    onMounted(() => {
      if (props.admin !== null) {
        adminsRole.value = props.admin.role[0].toUpperCase() + props.admin.role.slice(1)
        adminsOrganizationName.value = props.admin.organization.name
        adminsEmail.value = props.admin.email
      }
    })

    if (props.role === 'admin') {
      adminsRole.value = 'admin'
      adminsOrganizationName.value = organizationName
    }

    const save = async () => {
      try {
        if (props.admin !== null) {
          await root.$apollo.mutate({
            mutation: gql`
              mutation ($user: UserInput!) {
                updateUser(user: $user) {
                  id
                  email
                  role
                  organization {
                    id
                    name
                    color
                  }
                }
              }
            `,
            variables: {
              user: {
                id: props.admin.id,
                email: adminsEmail.value,
                role: adminsRole.value,
                organization_name: adminsOrganizationName.value,
              },
            },
          })
          emit('success')
          vm.$toastr.s('User successfully updated!')
        } else {
          await root.$apollo.mutate({
            mutation: gql`
              mutation ($user: UserInput!) {
                addUser(user: $user) {
                  id
                  email
                  role
                  organization {
                    id
                    name
                    color
                  }
                }
              }
            `,
            variables: {
              user: {
                email: adminsEmail.value,
                role: adminsRole.value,
                organization_name: adminsOrganizationName.value,
              },
            },
          })
          vm.$toastr.s('User successfully added!')
          emit('success')
        }
      } catch (err) {
        console.log(err)
        // TODO: errors
        vm.$toastr.e('Failed!')
      } finally {
        emit('update:admin', null)
        emit('update:show', false)
        isDialogVisible.value = false
      }
    }

    /* eslint-disable */
    const isValid = computed(() => {
      if (
        adminsEmail.value !== undefined &&
        adminsRole.value !== undefined &&
        adminsRole.value !== null &&
        adminsOrganizationName.value !== null &&
        adminsOrganizationName.value !== undefined
      ) {
        return true
      }

      return false
    })

    return {
      isDialogVisible,
      usersRole,
      adminsOrganizationName,
      adminsEmail,
      adminsRole,
      closeModal,
      save,
      validators: {
        required,
        emailValidator,
      },
      errorMessages,
      isValid,
    }
  },
}
</script>
